import { useNavigation } from '@remix-run/react';

import { Submission } from '@conform-to/dom';
import { FieldMetadata, SubmissionResult } from '@conform-to/react';
import { ZodSchema, z } from 'zod';

export type SuccessfulSubmission<T> = Submission<T> & { status: 'success'; value: T };

export type FormActionResponse<T = {}> = {
  status: 'success' | 'error';
  result: SubmissionResult;
} & T;

export function useLastResult<T>(response?: FormActionResponse<T>) {
  const navigation = useNavigation();
  return navigation.state === 'idle' ? response?.result : null;
}

export type SchemaType<T extends ZodSchema> = z.infer<T>;

type HTMLInputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export function getSafeInputProps<Schema>(
  field: FieldMetadata<Schema, any, any>,
  options: { type: HTMLInputType; includeError?: boolean } = { type: 'text', includeError: true },
) {
  const defaultIncludeError = options.includeError === undefined ? true : options.includeError;

  return {
    id: field.id,
    name: field.name,
    defaultValue: field.initialValue,
    type: options.type,
    error: defaultIncludeError ? field.errors : undefined,
  };
}
